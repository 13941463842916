import './App.css';

import { Fragment, useState, useEffect } from 'react';
import { Disclosure, Menu, Dialog, Transition } from '@headlessui/react';
import { BellIcon, MenuIcon, XIcon } from '@heroicons/react/outline';

const assets = {
  screen_welcome: {
    default_image: 'images/screen_welcome.png',
    title: 'Welcome',
    description: '',
    versions: [],
  },
  language_ask: {
    default_image: 'images/screen_languageask.png',
    title: 'Language Ask',
    description: '',
    versions: [],
  },
  check_registration: {
    default_image: 'images/screen_checkregistration.png',
    title: 'Check Registration',
    description: '',
    versions: [],
  },
  new_registration: {
    default_image: 'images/screen_newregistration.png',
    title: 'New Registration',
    description: '',
    versions: [
      {
        image: 'images/screen_newregistration.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_newregistration_01.png',
        title: 'Empty Form (Part 2)',
      },
      {
        image: 'images/screen_newregistration_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_newregistration_input_01.png',
        title: 'Form Input (Part 2)',
      },
      {
        image: 'images/screen_newregistration_error.png',
        title: 'Error Input',
      },
      {
        image: 'images/screen_newregistration_error_01.png',
        title: 'Error Input (Part 2)',
      },
    ]
  },
  update_registration: {
    default_image: 'images/screen_updatereg.png',
    title: 'Update Registration',
    description: '',
    versions: [
      {
        image: 'images/screen_updatereg.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_updatereg_01.png',
        title: 'Empty Form (Part 2)',
      },
      {
        image: 'images/screen_updatereg_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_updatereg_input_01.png',
        title: 'Form Input (Part 2)',
      },
      {
        image: 'images/screen_updatereg_error.png',
        title: 'Error Input',
      },
      {
        image: 'images/screen_updatereg_error_01.png',
        title: 'Error Input (Part 2)',
      },
    ]
  },
  change_ask: {
    default_image: 'images/screen_changeask.png',
    title: 'Change Confirmation',
    description: '',
    versions: [],
  },
  change_ask_name: {
    default_image: 'images/screen_changeask_name.png',
    title: 'Change Confirmation',
    description: '',
    versions: [],
  },
  change_ask_address: {
    default_image: 'images/screen_changeask_address.png',
    title: 'Change Confirmation',
    description: '',
    versions: [],
  },
  change_ask_party: {
    default_image: 'images/screen_changeask_party.png',
    title: 'Change Confirmation',
    description: '',
    versions: [],
  },
  change_ask_nameaddress: {
    default_image: 'images/screen_changeask_nameaddress.png',
    title: 'Change Confirmation',
    description: '',
    versions: [],
  },
  change_ask_nameaddressparty: {
    default_image: 'images/screen_changeask_nameaddressparty.png',
    title: 'Change Confirmation',
    description: '',
    versions: [],
  },
  change_ask_nameparty: {
    default_image: 'images/screen_changeask_nameparty.png',
    title: 'Change Confirmation',
    description: '',
    versions: [],
  },
  change_ask_addressparty: {
    default_image: 'images/screen_changeask_addressparty.png',
    title: 'Change Confirmation',
    description: '',
    versions: [],
  },
  previous_name: {
    default_image: 'images/screen_prevname.png',
    title: 'Previous Name',
    description: '',
    versions: [
      {
        image: 'images/screen_prevname.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_prevname_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_prevname_error.png',
        title: 'Error Input',
      },
    ]
  },
  previous_address: {
    default_image: 'images/screen_prevaddress.png',
    title: 'Previous Address',
    description: '',
    versions: [
      {
        image: 'images/screen_prevaddress.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_prevaddress_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_prevaddress_error.png',
        title: 'Error Input',
      },
    ]
  },
  new_name: {
    default_image: 'images/screen_newname.png',
    title: 'New Name',
    description: '',
    versions: [
      {
        image: 'images/screen_newname.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_newname_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_newname_error.png',
        title: 'Error Input',
      },
    ]
  },
  new_address: {
    default_image: 'images/screen_newaddress.png',
    title: 'New Address',
    description: '',
    versions: [
      {
        image: 'images/screen_newaddress.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_newaddress_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_newaddress_error.png',
        title: 'Error Input',
      },
    ]
  },
  disclaimer: {
    default_image: 'images/screen_disclaimer.png',
    title: 'Disclaimer',
    description: '',
    versions: [],
  },
  valid_citizen: {
    default_image: 'images/screen_iscitizen.png',
    title: 'Citizen Confirm',
    description: '',
    versions: [],
  },
  valid_age: {
    default_image: 'images/screen_isvalidage.png',
    title: 'Age Confirm',
    description: '',
    versions: [],
  },
  id_ask: {
    default_image: 'images/screen_idask.png',
    title: 'ID Ask',
    description: '',
    versions: [],
  },
  id_ask_input01: {
    default_image: 'images/screen_idask_input01.png',
    title: 'ID Ask',
    description: '',
    versions: [],
  },
  id_ask_input02: {
    default_image: 'images/screen_idask_input02.png',
    title: 'ID Ask',
    description: '',
    versions: [],
  },
  input_id: {
    default_image: 'images/screen_inputid.png',
    title: 'ID Info',
    description: '',
    versions: [
      {
        image: 'images/screen_inputid.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_inputid_01.png',
        title: 'Empty Form (Part 2)',
      },
      {
        image: 'images/screen_inputid_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_inputid_input_01.png',
        title: 'Form Input (Part 2)',
      },
      {
        image: 'images/screen_inputid_error.png',
        title: 'Error Input',
      },
      {
        image: 'images/screen_inputid_error_01.png',
        title: 'Error Input (Part 2)',
      },
      {
        image: 'images/dialog_inputid_warning.png',
        title: 'No ID Warning',
      },
    ]
  },
  ssn_ask_with_id: {
    default_image: 'images/screen_ssn_with_id.png',
    title: 'SSN',
    description: '',
    versions: [
      {
        image: 'images/screen_ssn_with_id.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_ssn_with_id_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_ssn_with_id_error.png',
        title: 'Error Input',
      },
      {
        image: 'images/dialog_ssn_input_warning.png',
        title: 'No ID, No SSN Warning',
      },
    ],
  },
  ssn_ask: {
    default_image: 'images/screen_ssn.png',
    title: 'SSN',
    description: '',
    versions: [
      {
        image: 'images/screen_ssn.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_ssn_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/dialog_ssn_input_warning.png',
        title: 'No SSN Warning',
      },
    ],
  },
  ssn_info: {
    default_image: 'images/screen_ssninfo.png',
    title: 'Address Info',
    description: '',
    versions: [
      {
        image: 'images/screen_ssninfo.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_ssninfo_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_ssninfo_error.png',
        title: 'Error Input',
      },
      {
        image: 'images/screen_ssninfo_error_01.png',
        title: 'Error Input (Part 2)',
      },
    ]
  },
  ssn_info_input_no_address: {
    default_image: 'images/screen_ssninfo_noaddress.png',
    title: 'Address Info (No Address)',
    description: '',
    versions: [],
  },
  paper_ask: {
    default_image: 'images/screen_paperask.png',
    title: 'Paper Form Ask',
    description: '',
    versions: [],
  },
  mailing_ask: {
    default_image: 'images/screen_mailingask.png',
    title: 'Mailing Ask',
    description: '',
    versions: [
      {
        image: 'images/screen_mailingask.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_mailingask_error.png',
        title: 'Error Input',
      },
    ],
  },
  mailing_ask_yes: {
    default_image: 'images/screen_mailingask_yes.png',
    title: 'Mailing Ask (Yes)',
    description: '',
    versions: [],
  },
  mailing_ask_no: {
    default_image: 'images/screen_mailingask_no.png',
    title: 'Mailing Ask (No)',
    description: '',
    versions: [],
  },
  mailing_form: {
    default_image: 'images/screen_mailingform.png',
    title: 'Mailing Form',
    description: '',
    versions: [
      {
        image: 'images/screen_mailingform.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_mailingform_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_mailingform_error.png',
        title: 'Error Input',
      },
    ]
  },
  party_ask: {
    default_image: 'images/screen_partyask.png',
    title: 'Political Party Ask',
    description: '',
    versions: [
      {
        image: 'images/screen_partyask.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_partyask_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_partyask_error.png',
        title: 'Error Input',
      },
      {
        image: 'images/screen_partyask_error_01.png',
        title: 'Error Input (Other Field)',
      },
    ],
  },
  poll_ask: {
    default_image: 'images/screen_pollask.png',
    title: 'Polling Ask',
    description: '',
    versions: [
      {
        image: 'images/screen_pollask.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_pollask_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_pollask_error.png',
        title: 'Error Input',
      },
    ],
  },
  help_ask: {
    default_image: 'images/screen_helpask.png',
    title: 'Assistance Ask',
    description: '',
    versions: [
      {
        image: 'images/screen_helpask.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_helpask_input.png',
        title: 'Form Input (No)',
      },
      {
        image: 'images/screen_helpask_error.png',
        title: 'Error Input',
      },
      {
        image: 'images/screen_helpask_input02.png',
        title: 'Form Input (Yes)',
      },
      {
        image: 'images/screen_helpask_input03.png',
        title: 'Form Input (Yes - Language)',
      },
      {
        image: 'images/screen_helpask_error01.png',
        title: 'Error Input (Yes)',
      },
      {
        image: 'images/screen_helpask_error02.png',
        title: 'Error Input (Yes - Language)',
      },
    ],
  },
  ballot_request: {
    default_image: 'images/screen_ballotrequest.png',
    title: 'Ballot Request Ask',
    description: '',
    versions: [
      {
        image: 'images/screen_ballotrequest.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_ballotrequest_error.png',
        title: 'Error Input',
      },
    ],
  },
  ballot_request_yes: {
    default_image: 'images/screen_ballotrequest_yes.png',
    title: 'Ballot Request (Yes)',
    description: '',
    versions: [
      {
        image: 'images/screen_ballotrequest_yes.png',
        title: 'Form Input (Yes)',
      },
      {
        image: 'images/screen_ballotrequest_yes_01.png',
        title: 'Form Input (Yes, Part 2)',
      },
      {
        image: 'images/screen_ballotrequest_yes_error.png',
        title: 'Error Input',
      },
      {
        image: 'images/screen_ballotrequest_yes_error_01.png',
        title: 'Error Input (Part 2)',
      },
      {
        image: 'images/screen_ballotrequest_residential_input.png',
        title: 'Form Input (Yes - Residential)',
      },
      {
        image: 'images/screen_ballotrequest_residential_input_01.png',
        title: 'Form Input (Yes - Residential, Part 2) ',
      },
      {
        image: 'images/screen_ballotrequest_mailing_input.png',
        title: 'Form Input (Yes - Mailing)',
      },
      {
        image: 'images/screen_ballotrequest_mailing_input_01.png',
        title: 'Form Input (Yes - Mailing, Part 2)',
      },
      {
        image: 'images/screen_ballotrequest_alternate.png',
        title: 'Empty Form (Yes - Alternate)',
      },
      {
        image: 'images/screen_ballotrequest_alternate_input.png',
        title: 'Form Input (Yes - Alternate)',
      },
      {
        image: 'images/screen_ballotrequest_alternate_error.png',
        title: 'Error Input (Yes - Alternate)',
      },
    ],
  },
  ballot_request_no: {
    default_image: 'images/screen_ballotrequest_no.png',
    title: 'Ballot Request (No)',
    description: '',
    versions: [],
  },
  review: {
    default_image: 'images/screen_review.png',
    title: 'Review',
    description: '',
    versions: [
      {
        image: 'images/screen_review.png',
        title: 'Personal Info',
      },
      {
        image: 'images/screen_review_01.png',
        title: 'Residential Info',
      },
      {
        image: 'images/screen_review_04.png',
        title: 'Previous Name',
      },
      {
        image: 'images/screen_review_05.png',
        title: 'Previous Address',
      },
      {
        image: 'images/screen_review_02.png',
        title: 'License Info',
      },
      {
        image: 'images/screen_review_06.png',
        title: 'SSN Info',
      },
      {
        image: 'images/screen_review_03.png',
        title: 'Additional Info',
      },
    ],
  },
  signature_review: {
    default_image: 'images/screen_signaturereview.png',
    title: 'Signature Review',
    description: '',
    versions: [
      {
        image: 'images/screen_signaturereview.png',
        title: 'Initial View',
      },
      {
        image: 'images/dialog_signature.png',
        title: 'Signature Dialog',
      },
      {
        image: 'images/dialog_signature_input.png',
        title: 'Signature Signed',
      },
      {
        image: 'images/screen_signaturereview_complete.png',
        title: 'Signature Review',
      },
    ],
  },
  ballot_signature: {
    default_image: 'images/screen_ballotsignature.png',
    title: 'Ballot Request Confirmation',
    description: '',
    versions: [
      {
        image: 'images/screen_ballotsignature.png',
        title: 'Declaration',
      },
      {
        image: 'images/screen_ballotsignature_01.png',
        title: 'Declaration (Part 2)',
      },
      {
        image: 'images/screen_ballotsignature_input.png',
        title: 'Confirmation',
      },
    ],
  },
  assistance_ask: {
    default_image: 'images/screen_assistask.png',
    title: 'Assistance Ask',
    description: '',
    versions: [],
  },
  assistance_ask_yes: {
    default_image: 'images/screen_assistask_yes.png',
    title: 'Assistance Ask',
    description: '',
    versions: [],
  },
  assistance_ask_no: {
    default_image: 'images/screen_assistask_no.png',
    title: 'Assistance Ask',
    description: '',
    versions: [],
  },
  assistance_form: {
    default_image: 'images/screen_assistform.png',
    title: 'Assistance Form',
    description: '',
    versions: [
      {
        image: 'images/screen_assistform.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_assistform_01.png',
        title: 'Empty Form (Part 2)',
      },
      {
        image: 'images/screen_assistform_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_assistform_input_01.png',
        title: 'Form Input (Part 2)',
      },
      {
        image: 'images/screen_assistform_error.png',
        title: 'Form Error',
      },
      {
        image: 'images/screen_assistform_error_01.png',
        title: 'Form Error (Part 2)',
      },
    ],
  },
  assistance_review: {
    default_image: 'images/screen_assistreview.png',
    title: 'Assistance Review',
    description: '',
    versions: [],
  },
  assistance_review_saving: {
    default_image: 'images/dialog_assistsaving.png',
    title: 'Saving Registration',
    description: '',
    versions: [],
  },
  registration_complete: {
    default_image: 'images/screen_regcomplete.png',
    title: 'Registration Complete',
    description: '',
    versions: [],
  },


  not_eligible: {
    default_image: 'images/screen_noteligible.png',
    title: 'Not Eligible',
    description: '',
    versions: [],
  },
  optin_form: {
    default_image: 'images/screen_optin.png',
    title: 'Opt-In Form',
    description: '',
    versions: [
      {
        image: 'images/screen_optin.png',
        title: 'Empty Form',
      },
      {
        image: 'images/screen_optin_input.png',
        title: 'Form Input',
      },
      {
        image: 'images/screen_optin_error.png',
        title: 'Form Error',
      },
    ],
  },
  optin_saving: {
    default_image: 'images/dialog_optinsaving.png',
    title: 'Saving Submission',
    description: '',
    versions: [],
  },
  thankyou: {
    default_image: 'images/screen_thankyou.png',
    title: 'Thank You',
    description: '',
    versions: [],
  },
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

function App() {
  const [open, setOpen] = useState(false);
  const [connection, setConnection] = useState('online');
  const [rollStatus, setRollStatus] = useState('new_registration');
  const [isEligibleDisclaimer, setIsEligibleDisclaimer] = useState(true);
  const [isEligibleCitizen, setIsEligibleCitizen] = useState(true);
  const [isEligibleAge, setIsEligibleAge] = useState(true);
  const [newNameStatus, setNewNameStatus] = useState('updated');
  const [newAddressStatus, setNewAddressStatus] = useState('updated');
  const [idInfo, setIDInfo] = useState('has_id');
  const [hasMailing, setHasMailing] = useState(false);
  const [hasNoSSN, setHasNoSSN] = useState(false);
  const [hasNoAddress, setHasNoAddress] = useState(false);
  const [hasAssist, setHasAssist] = useState(false);
  const [updateName, setUpdateName] = useState(false);
  const [updateAddress, setUpdateAddress] = useState(false);
  const [updateParty, setUpdateParty] = useState(false);
  const [updateNone, setUpdateNone] = useState(true);
  const [isMailinRequest, setIsMailinRequest] = useState(false);

  const [workflow, setWorkflow] = useState([]);
  const [registrationType, setRegistrationType] = useState(null);

  const updateWorkflow = () => {
    let _workflow = [
      assets.screen_welcome,
      assets.language_ask,
      assets.check_registration,
    ];

    if (connection === 'offline') {
      /*if (rollStatus === 'first_time') {
        _workflow.push(...[
          assets.not_on_roll_offline,
          assets.not_on_roll_offline_register,
        ]);
      } else if (rollStatus === 'name_change') {
        _workflow.push(...[
          assets.not_on_roll_offline,
          assets.not_on_roll_offline_name_change,
          assets.update_name_ask,
        ]);

        if (newNameStatus === 'updated') {
          _workflow.push(...[
            assets.update_name_ask_updated,
            assets.new_name_form,
            assets.new_name_form_input,
          ]);
        } else if (newNameStatus === 'not_updated') {
          _workflow.push(...[
            assets.update_name_ask_not_updated,
            assets.name_not_updated,
            assets.thank_you_empty,
          ]);

          setWorkflow(_workflow);
          setRegistrationType('None');
          return;
        } else {
          _workflow.push(...[
            assets.update_name_ask_dont_know,
            assets.name_not_updated,
            assets.thank_you_empty,
          ]);

          setWorkflow(_workflow);
          setRegistrationType('None');
          return;
        }
      } else if (rollStatus === 'address_change') {
        _workflow.push(...[
          assets.not_on_roll_offline,
          assets.not_on_roll_offline_address_change,
          assets.update_address_ask,
        ]);

        if (newAddressStatus === 'updated') {
          _workflow.push(...[
            assets.update_address_ask_updated,
            assets.new_address_form,
            assets.new_address_form_input,
          ]);
        } else if (newAddressStatus === 'not_updated') {
          _workflow.push(...[
            assets.update_address_ask_not_updated,
            assets.not_eligible,
            assets.not_eligible_input,
            assets.thank_you,
          ]);

          setWorkflow(_workflow);
          setRegistrationType('None');
          return;
        } else {
          _workflow.push(...[
            assets.update_address_ask_dont_know,
            assets.not_eligible,
            assets.not_eligible_input,
            assets.thank_you,
          ]);

          setWorkflow(_workflow);
          setRegistrationType('None');
          return;
        }
      } else if (rollStatus === 'name_address_change') {
        _workflow.push(...[
          assets.not_on_roll_offline,
          assets.not_on_roll_offline_name_address_change,
          assets.update_name_ask,
        ]);

        if (newNameStatus === 'updated') {
          _workflow.push(...[
            assets.update_name_ask_updated,
            assets.new_name_form,
            assets.new_name_form_input,
          ]);
        } else if (newNameStatus === 'not_updated') {
          _workflow.push(...[
            assets.update_name_ask_not_updated,
            assets.name_not_updated,
            assets.thank_you_empty,
          ]);

          setWorkflow(_workflow);
          setRegistrationType('None');
          return;
        } else {
          _workflow.push(...[
            assets.update_name_ask_dont_know,
            assets.name_not_updated,
            assets.thank_you_empty,
          ]);

          setWorkflow(_workflow);
          setRegistrationType('None');
          return;
        }

        if (newAddressStatus === 'updated') {
          _workflow.push(...[
            assets.update_address_ask,
            assets.update_address_ask_updated,
            assets.new_address_form,
            assets.new_address_form_input,
          ]);
        } else if (newAddressStatus === 'not_updated') {
          _workflow.push(...[
            assets.update_address_ask_not_updated,
            assets.not_eligible,
            assets.not_eligible_input,
            assets.thank_you,
          ]);

          setWorkflow(_workflow);
          setRegistrationType('None');
          return;
        }  else {
          _workflow.push(...[
            assets.update_address_ask_dont_know,
            assets.not_eligible,
            assets.not_eligible_input,
            assets.thank_you,
          ]);

          setWorkflow(_workflow);
          setRegistrationType('None');
          return;
        }
      }

      _workflow.push(...[
        assets.dob_consent,
        assets.eligibility,
      ]);

      if (!isEligible) {
        _workflow.push(assets.not_eligible);
        _workflow.push(assets.not_eligible_input);
        _workflow.push(assets.thank_you);

        setWorkflow(_workflow);
        setRegistrationType('Not Eligible');
        return;
      }

      _workflow.push(...[
        assets.eligibility_input,
        assets.id_ask,
      ]);*/
    } else {
      if (rollStatus === 'new_registration') {
        _workflow.push(...[
          assets.new_registration,
          assets.disclaimer,
        ]);
      } else if (rollStatus === 'update_registration') {
        _workflow.push(...[
          assets.update_registration,
          assets.change_ask,
        ]);

        if (rollStatus === 'update_registration') {
          let changeKey = 'change_ask_';
    
          if (updateName) {
            changeKey += 'name';
          }
    
          if (updateAddress) {
            changeKey += 'address';
          }
    
          if (updateParty) {
            changeKey += 'party';
          }

          if (changeKey != 'change_ask_') {
            _workflow.push(...[
              assets[changeKey],
            ]);
          } else {
            _workflow.push(assets.optin_form);
            _workflow.push(assets.optin_saving);
            _workflow.push(assets.thankyou);
            
            setWorkflow(_workflow);
            setRegistrationType('On Roll');
            return;
          }
        }

        _workflow.push(...[
          assets.disclaimer,
        ]);
      }

      if (!isEligibleDisclaimer) {
        _workflow.push(assets.not_eligible);
        _workflow.push(assets.optin_form);
        _workflow.push(assets.optin_saving);
        _workflow.push(assets.thankyou);

        setWorkflow(_workflow);
        setRegistrationType('Not Eligible');
        return;
      }

      _workflow.push(...[
        assets.valid_citizen,
      ]);

      if (!isEligibleCitizen) {
        _workflow.push(assets.not_eligible);
        _workflow.push(assets.optin_form);
        _workflow.push(assets.optin_saving);
        _workflow.push(assets.thankyou);

        setWorkflow(_workflow);
        setRegistrationType('Not Eligible');
        return;
      }

      _workflow.push(...[
        assets.valid_age,
      ]);

      if (!isEligibleAge) {
        _workflow.push(assets.not_eligible);
        _workflow.push(assets.optin_form);
        _workflow.push(assets.optin_saving);
        _workflow.push(assets.thankyou);

        setWorkflow(_workflow);
        setRegistrationType('Not Eligible');
        return;
      }

      _workflow.push(...[
        assets.id_ask,
      ]);
    }

    if (idInfo === 'has_id') {
      _workflow.push(...[
        assets.input_id,
        assets.ssn_ask_with_id,
      ]);
    } else if (idInfo === 'no_id') {
      _workflow.push(...[
        assets.id_ask_input01,
        assets.not_eligible,
        assets.optin_form,
        assets.optin_saving,
        assets.thankyou,
      ]);

      setWorkflow(_workflow);
      setRegistrationType('Not Eligible');
      return;
    } else if (idInfo === 'has_ssn') {
      _workflow.push(...[
        assets.id_ask_input01,
        assets.ssn_ask,
      ]);

      if (hasNoSSN) {
        _workflow.push(...[
          assets.not_eligible,
          assets.optin_form,
          assets.optin_saving,
          assets.thankyou,
        ]);
  
        setWorkflow(_workflow);
        setRegistrationType('Not Eligible');
        return;
      }

      _workflow.push(...[
        assets.ssn_info,
      ]);

      if (hasNoAddress) {
        _workflow.push(...[
          assets.ssn_info_input_no_address,
          assets.paper_ask,
          assets.optin_form,
          assets.optin_saving,
          assets.thankyou,
        ]);
  
        setWorkflow(_workflow);
        setRegistrationType('Not Eligible');
        return;
      }
    } else if (idInfo === 'no_id_ssn') {
      _workflow.push(...[
        assets.id_ask_input02,
        assets.ssn_info,
      ]);
    }

    if (rollStatus === 'update_registration') {
      if (updateName || updateAddress || updateParty) {
        _workflow.push(...[
          assets.party_ask,
          ...(updateName ? [assets.previous_name] : []),
          ...(updateName ? [assets.new_name] : []),
          ...(updateAddress ? [assets.previous_address] : []),
          ...(updateAddress ? [assets.new_address] : []),
        ]);
      } else {
        setWorkflow(_workflow);
        setRegistrationType('Not Eligible');
        return;
      }
    } else {
      _workflow.push(...[
        assets.party_ask,
      ]);
    }

    _workflow.push(...[
      assets.mailing_ask,
      ...(hasMailing ? [assets.mailing_ask_no] : [assets.mailing_ask_yes]),
      ...(hasMailing ? [assets.mailing_form] : []),
      assets.poll_ask,
      assets.help_ask,
      assets.review,
      assets.signature_review,

      ...(idInfo === 'has_id' && isMailinRequest ? [assets.ballot_request, assets.ballot_request_yes] : []),
      ...(idInfo === 'has_id' && !isMailinRequest ? [assets.ballot_request, assets.ballot_request_no] : []),
      ...(idInfo === 'has_id' && isMailinRequest ? [assets.ballot_signature] : []),

      assets.assistance_ask,
      ...(hasAssist ? [assets.assistance_ask_yes] : [assets.assistance_ask_no]),
      ...(hasAssist ? [assets.assistance_form] : []),
      ...(hasAssist ? [assets.assistance_review] : []),
      ...(hasAssist ? [assets.assistance_review_saving] : []),
      assets.registration_complete,
    ]);

    setRegistrationType('Online Registration');
    setWorkflow(_workflow);
  };

  useEffect(() => {
    updateWorkflow();
  }, [connection, rollStatus, isEligibleDisclaimer, isEligibleAge, isEligibleCitizen, newNameStatus, 
    newAddressStatus, idInfo, hasNoSSN, hasNoAddress, updateName, updateAddress, updateParty, 
    updateNone, hasMailing, hasAssist, isMailinRequest
  ]);

  return (
    <>
      <div className="min-h-full">
        <Disclosure as="nav" className="border-b border-gray-200 bg-primary">
          {({ open }) => (
            <>
              <div className="px-4 mx-auto text-white max-w-7xl sm:px-6 lg:px-8">
                <div className="flex justify-between h-16">
                  <div className="flex grow">
                    <div className="flex items-center grow">
                      <h2 className="font-bold">PA Vote for Change Workflow</h2>
                    </div>
                    <div className="hidden sm:-my-px sm:ml-6 sm:flex sm:space-x-8">
                      <a
                        key="settings"
                        href="javascript: void(0);"
                        onClick={() => setOpen(true)}
                        className={classNames('border-transparent text-white inline-flex items-center px-1 pt-1 border-b-2 text-sm font-medium')}
                      >
                        Settings
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </Disclosure>

        <div className="py-10">
          <header>
            <div className="pb-4 mx-auto max-w-7xl">
              <h1 className="text-3xl font-bold leading-tight text-gray-900">Current Workflow</h1>
            </div>
          </header>
          <main>
            <div className="flex items-start content-start mx-auto space-x-8 overflow-x-auto bg-gray-200 max-w-7xl sm:px-6 lg:px-8">
              {workflow.map((item, index) => {
                return (
                  <div className="flex-shrink-0 my-4 overflow-hidden bg-white shadow sm:rounded-lg w-96">
                    <div className="px-4 py-5 sm:px-6 bg-primary">
                      <h3 className="text-lg font-medium leading-6 text-lightgrey">{item.title}</h3>
                      <p className="max-w-2xl mt-1 text-sm text-lightgrey">{item.description}</p>
                    </div>
                    <div className="border-t border-gray-200">
                      <img id={'workflow_image_'+index} src={item.default_image} />
                    </div>
                    {item.versions.length > 0 && (
                      <div className="px-4 py-5 sm:px-6">
                        <ul role="list" className="divide-y divide-gray-300">
                        {item.versions.map((version) => {
                          return (<li className="px-2 py-2"><a href="javascript:void(0);" onClick={() => {
                            document.getElementById('workflow_image_'+index).src = version.image;
                          }}>{version.title}</a></li>);
                        })}
                        </ul>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
            <div className="mx-auto mt-4 max-w-7xl">
              <p>Total Steps: {workflow.length}</p>
              <p>Registration Type: {registrationType}</p>
            </div>
          </main>
        </div>
      </div>

      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <div className="fixed inset-0" />

          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 pointer-events-none sm:pl-16">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="w-screen max-w-2xl pointer-events-auto">
                    <div className="flex flex-col h-full pb-6 overflow-y-scroll bg-white shadow-xl">
                      <div className="px-4 py-6 bg-secondary sm:px-6">
                        <div className="flex items-center justify-between">
                          <h2 className="text-lg font-medium font-bold text-white" id="slide-over-title">Workflow Settings</h2>
                          <div className="flex items-center ml-3 h-7">
                            <button type="button" onClick={() => setOpen(false)} className="text-indigo-200 rounded-md bg-secondary hover:text-white focus:outline-none focus:ring-2 focus:ring-white">
                              <span className="sr-only">Close panel</span>
                              <svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                                <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="pr-8 text-sm font-bold text-white">Use the settings below to update the application workflow process as the registrant will see it.</p>
                        </div>
                      </div>
                      <div className="relative flex-1 px-4 mt-2 sm:px-6">
                        {/* Replace with your content */}
                        <div className="absolute inset-0 px-4 sm:px-6">

                          <div className="pt-2 pb-10">
                            {/*<fieldset className="mt-2">
                              <legend className="text-base font-medium text-gray-900 contents">Connection</legend>
                              <p className="text-sm text-gray-500">If the device is currently online or offline.</p>
                              <div className="mt-4 space-y-4">
                                <div className="flex items-center">
                                  <input
                                    id="connection-online"
                                    name="connection"
                                    type="radio"
                                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                    checked={(connection === 'online' ? true : false)}
                                    onChange={() => setConnection('online')}
                                  />
                                  <label htmlFor="connection-online" className="block ml-3 text-sm font-medium text-gray-700">
                                    Online
                                  </label>
                                </div>
                                <div className="flex items-center">
                                  <input
                                    id="connection-offline"
                                    name="connection"
                                    type="radio"
                                    className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                    checked={(connection === 'offline' ? true : false)}
                                    onChange={() => setConnection('offline')}
                                  />
                                  <label htmlFor="connection-offline" className="block ml-3 text-sm font-medium text-gray-700">
                                    Offline
                                  </label>
                                </div>
                              </div>
                            </fieldset>*/}

                            {connection === 'online' && (
                              <fieldset className="mt-8">
                                <legend className="text-base font-medium text-gray-900 contents">Registration Type</legend>
                                <p className="text-sm text-gray-500">If the registrant is currently on the roll or not.</p>
                                <div className="mt-4 space-y-4">
                                  <div className="flex items-center">
                                    <input
                                      id="rollstatus-new_registration"
                                      name="rollstatus"
                                      type="radio"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={(rollStatus === 'new_registration' ? true : false)}
                                      onChange={() => setRollStatus('new_registration')}
                                    />
                                    <label htmlFor="rollstatus-new_registration" className="block ml-3 text-sm font-medium text-gray-700">
                                      New Registration
                                    </label>
                                  </div>
                                  <div className="flex items-center">
                                    <input
                                      id="rollstatus-updateregistration"
                                      name="rollstatus"
                                      type="radio"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={(rollStatus === 'update_registration' ? true : false)}
                                      onChange={() => setRollStatus('update_registration')}
                                    />
                                    <label htmlFor="rollstatus-updateregistration" className="block ml-3 text-sm font-medium text-gray-700">
                                      Update Registration
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            )}

                            {rollStatus === 'update_registration' && (
                              <fieldset className="mt-8">
                                <legend className="text-base font-medium text-gray-900 contents">Update Type</legend>
                                <p className="text-sm text-gray-500">The type of update the registrant is making.</p>
                                <div className="mt-4 space-y-4">
                                  <div className="flex items-center">
                                    <input
                                      id="updatetype-name"
                                      name="updatetype_name"
                                      type="checkbox"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={updateName}
                                      onChange={() => {
                                        setUpdateNone(false);
                                        setUpdateName(!updateName);
                                      }}
                                    />
                                    <label htmlFor="updatetype-name" className="block ml-3 text-sm font-medium text-gray-700">
                                      Update Name
                                    </label>
                                  </div>
                                  <div className="flex items-center">
                                    <input
                                      id="updatetype-address"
                                      name="updatetype_address"
                                      type="checkbox"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={updateAddress}
                                      onChange={() => {
                                        setUpdateNone(false);
                                        setUpdateAddress(!updateAddress);
                                      }}
                                    />
                                    <label htmlFor="updatetype-address" className="block ml-3 text-sm font-medium text-gray-700">
                                      Update Address
                                    </label>
                                  </div>
                                  <div className="flex items-center">
                                    <input
                                      id="updatetype-party"
                                      name="updatetype_party"
                                      type="checkbox"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={updateParty}
                                      onChange={() => {
                                        setUpdateNone(false);
                                        setUpdateParty(!updateParty);
                                      }}
                                    />
                                    <label htmlFor="updatetype-party" className="block ml-3 text-sm font-medium text-gray-700">
                                      Update Party
                                    </label>
                                  </div>
                                  <div className="flex items-center">
                                    <input
                                      id="updatetype-none"
                                      name="updatetype_none"
                                      type="checkbox"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={updateNone}
                                      onChange={() => {
                                        setUpdateName(false);
                                        setUpdateAddress(false);
                                        setUpdateParty(false);
                                        setUpdateNone(!updateNone);
                                      }}
                                    />
                                    <label htmlFor="updatetype-party" className="block ml-3 text-sm font-medium text-gray-700">
                                      No Updates
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            )}

                            {(rollStatus === 'new_registration' || (rollStatus === 'update_registration' && !updateNone)) && (
                              <fieldset className="mt-8">
                                <legend className="text-base font-medium text-gray-900 contents">Eligibility Questions</legend>
                                <p className="text-sm text-gray-500">Checks to verify if the registrant is eligible to register.</p>
                                <div className="mt-4 space-y-4">
                                <div className="flex items-center">
                                    <input
                                      id="is_eligible_disclaimer"
                                      name="is_eligible_disclaimer"
                                      type="checkbox"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={isEligibleDisclaimer}
                                      onChange={() => setIsEligibleDisclaimer(!isEligibleDisclaimer)}
                                    />
                                    <label htmlFor="is_eligible_disclaimer" className="block ml-3 text-sm font-medium text-gray-700">
                                      Confirms Disclaimer?
                                    </label>
                                  </div><div className="flex items-center">
                                    <input
                                      id="is_eligible_citizen"
                                      name="is_eligible_citizen"
                                      type="checkbox"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={isEligibleCitizen}
                                      onChange={() => setIsEligibleCitizen(!isEligibleCitizen)}
                                    />
                                    <label htmlFor="is_eligible_citizen" className="block ml-3 text-sm font-medium text-gray-700">
                                      Is Eligible U.S. Citizen?
                                    </label>
                                  </div>
                                  <div className="flex items-center">
                                    <input
                                      id="is_eligible_age"
                                      name="is_eligible_age"
                                      type="checkbox"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={isEligibleAge}
                                      onChange={() => setIsEligibleAge(!isEligibleAge)}
                                    />
                                    <label htmlFor="is_eligible_age" className="block ml-3 text-sm font-medium text-gray-700">
                                      Is Eligible Age?
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            )}

                            {(rollStatus === 'new_registration' || (rollStatus === 'update_registration' && !updateNone)) && isEligibleDisclaimer && isEligibleAge && isEligibleCitizen && (
                              <fieldset className="mt-8">
                                <legend className="text-base font-medium text-gray-900 contents">ID Information</legend>
                                <p className="text-sm text-gray-500">What type of ID information the registrant has available.</p>
                                <div className="mt-4 space-y-4">
                                  <div className="flex items-center">
                                    <input
                                      id="idinfo-hasid"
                                      name="idinfo"
                                      type="radio"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={(idInfo === 'has_id' ? true : false)}
                                      onChange={() => setIDInfo('has_id')}
                                    />
                                    <label htmlFor="idinfo-hasid" className="block ml-3 text-sm font-medium text-gray-700">
                                      PA Driver's License or PennDOT ID number on person
                                    </label>
                                  </div>
                                  {/*<div className="flex items-center">
                                    <input
                                      id="idinfo-noid"
                                      name="idinfo"
                                      type="radio"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={(idInfo === 'no_id' ? true : false)}
                                      onChange={() => setIDInfo('no_id')}
                                    />
                                    <label htmlFor="idinfo-noid" className="block ml-3 text-sm font-medium text-gray-700">
                                    PA Driver's License or PennDOT ID number not on person
                                    </label>
                                  </div>*/}
                                  <div className="flex items-center">
                                    <input
                                      id="idinfo-hasssn"
                                      name="idinfo"
                                      type="radio"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={(idInfo === 'has_ssn' ? true : false)}
                                      onChange={() => setIDInfo('has_ssn')}
                                    />
                                    <label htmlFor="idinfo-hasssn" className="block ml-3 text-sm font-medium text-gray-700">
                                      Social Security Number
                                    </label>
                                  </div>
                                  <div className="flex items-center">
                                    <input
                                      id="idinfo-noidssn"
                                      name="idinfo"
                                      type="radio"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={(idInfo === 'no_id_ssn' ? true : false)}
                                      onChange={() => setIDInfo('no_id_ssn')}
                                    />
                                    <label htmlFor="idinfo-noidssn" className="block ml-3 text-sm font-medium text-gray-700">
                                      No PA Driver's License, PennDOT ID number, or SSN
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            )}

                            {(idInfo === 'has_ssn') && (
                              <fieldset className="mt-8">
                                <legend className="text-base font-medium text-gray-900 contents">SSN Info Not Available?</legend>
                                <p className="text-sm text-gray-500">Option for individuals that do not know SSN.</p>
                                <div className="mt-4 space-y-4">
                                  <div className="flex items-center">
                                    <input
                                      id="has_no_ssn"
                                      name="has_no_ssn"
                                      type="checkbox"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={hasNoSSN}
                                      onChange={() => setHasNoSSN(!hasNoSSN)}
                                    />
                                    <label htmlFor="has_no_ssn" className="block ml-3 text-sm font-medium text-gray-700">
                                      SSN Info Not Available
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            )}

                            {(idInfo === 'has_ssn' && !hasNoSSN) && (
                              <fieldset className="mt-8">
                                <legend className="text-base font-medium text-gray-900 contents">Has No Street/Permanent Address?</legend>
                                <p className="text-sm text-gray-500">Option for individuals that have no street address or permanent address.</p>
                                <div className="mt-4 space-y-4">
                                  <div className="flex items-center">
                                    <input
                                      id="has_no_address"
                                      name="has_no_address"
                                      type="checkbox"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={hasNoAddress}
                                      onChange={() => setHasNoAddress(!hasNoAddress)}
                                    />
                                    <label htmlFor="has_no_address" className="block ml-3 text-sm font-medium text-gray-700">
                                      Has No Street/Permanent Address
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            )}

                            {(rollStatus === 'new_registration' || (rollStatus === 'update_registration' && !updateNone)) && isEligibleDisclaimer && isEligibleAge 
                            && isEligibleCitizen && (idInfo === 'has_id' || idInfo === 'no_id_ssn' || (idInfo === 'has_ssn' && !hasNoSSN && !hasNoAddress)) && (
                              <fieldset className="mt-8">
                                <legend className="text-base font-medium text-gray-900 contents">Has Mailing Address?</legend>
                                <p className="text-sm text-gray-500">Checks if the registrant has a separate mailing address.</p>
                                <div className="mt-4 space-y-4">
                                  <div className="flex items-center">
                                    <input
                                      id="has_mailing"
                                      name="has_mailing"
                                      type="checkbox"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={hasMailing}
                                      onChange={() => setHasMailing(!hasMailing)}
                                    />
                                    <label htmlFor="has_mailing" className="block ml-3 text-sm font-medium text-gray-700">
                                      Has Separate Mailing Address
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            )}

                            {(rollStatus === 'new_registration' || (rollStatus === 'update_registration' && !updateNone)) && isEligibleDisclaimer && isEligibleAge 
                            && isEligibleCitizen && (idInfo === 'has_id' || idInfo === 'no_id_ssn' || (idInfo === 'has_ssn' && !hasNoSSN && !hasNoAddress)) && (
                              <fieldset className="mt-8">
                                <legend className="text-base font-medium text-gray-900 contents">Has Assistance?</legend>
                                <p className="text-sm text-gray-500">Indicates if the user had assistance filling out their registration.</p>
                                <div className="mt-4 space-y-4">
                                  <div className="flex items-center">
                                    <input
                                      id="has_assistance"
                                      name="has_assistance"
                                      type="checkbox"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={hasAssist}
                                      onChange={() => setHasAssist(!hasAssist)}
                                    />
                                    <label htmlFor="has_assistance" className="block ml-3 text-sm font-medium text-gray-700">
                                      Has Assistance?
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            )}

                            {(rollStatus === 'new_registration' || (rollStatus === 'update_registration' && !updateNone)) && isEligibleDisclaimer && isEligibleAge 
                            && isEligibleCitizen && (idInfo === 'has_id') && (
                              <fieldset className="mt-8">
                                <legend className="text-base font-medium text-gray-900 contents">Is Mail-In Ballot Request?</legend>
                                <p className="text-sm text-gray-500">Indicates if the user is also requesting a mail-in ballot.</p>
                                <div className="mt-4 space-y-4">
                                  <div className="flex items-center">
                                    <input
                                      id="is_mailin_ballot_request"
                                      name="is_mailin_ballot_request"
                                      type="checkbox"
                                      className="w-4 h-4 text-indigo-600 border-gray-300 focus:ring-indigo-500"
                                      checked={isMailinRequest}
                                      onChange={() => setIsMailinRequest(!isMailinRequest)}
                                    />
                                    <label htmlFor="is_mailin_ballot_request" className="block ml-3 text-sm font-medium text-gray-700">
                                      Mail-In Ballot Request?
                                    </label>
                                  </div>
                                </div>
                              </fieldset>
                            )}
                            </div>
                        </div>
                        {/* /End replace */}
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default App;
